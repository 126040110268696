import {request} from './axios.config'
import axios from 'axios'

const CLOUDINARY_URL = '' // TODO

export const common = {
  async uploadByQiniu(file: File | Blob): Promise<string> {
    const data = new FormData()
    data.append('file', file)
    data.append('upload_preset', 'easy-email-test')

    const res = await axios.post<{url: string}>(CLOUDINARY_URL, data)
    return res.data.url
  },
  uploadByUrl(url: string) {
    return request.get<string>('/upload/user/upload-by-url', {
      params: {
        url,
      },
    })
  },
  getMenu(): Promise<IAppMenuItem[]> {
    return Promise.resolve([
      {
        name: '数据模板',
        icon: 'bar-chart',
        isOpen: true,
        children: [
          {
            name: '数据模板',
            url: '/',
          },
        ],
      },
    ])
  },
  sendTestEmail(data: {toEmail: string; subject: string; html: string; text: string}) {
    return fetch('https://smtp-api.webcloud.cloud', {
      body: JSON.stringify({
        from: 'servizi@webcloud.it',
        to: data.toEmail,
        subject: data.subject,
        html: data.html,
        text: data.text,
      }),
      headers: {
        'Authorization': `Bearer ${window.atob(window.localStorage.getItem('WCSAT') || '')}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
  },
}

export interface IAppMenuItem {
  name: string
  url?: string
  icon: string
  isOpen?: boolean
  children: IAppSubMenuItem[]
}

export interface IAppSubMenuItem {
  name: string
  url: string
  isOpen?: boolean
}
